<template>
  <div class="login">
    <img alt="Vue logo" src="../assets/login.png">
    <div class="center">
      <van-cell-group class="van-cell-group" :border="false">
          <van-field  style="border:0"  border input-align="right" v-model="account" label="账号" placeholder="请输入您要登录的手机号" />
        </van-cell-group>
        <van-cell-group class="van-cell-group" :border="false">
          <van-field style="border:0"  input-align="right" type="password" v-model="password" label="密码" placeholder="请输入您设置的密码" />
        </van-cell-group>
      <div class="login-btn" v-on:click="login" @click.stop="doThis">登录</div>
  <van-divider class="register">
    <div class="register-btn" v-on:click="$router.push('/register')">注册账号</div>
    <div class="password-btn" v-on:click="$router.push('/changepw')">忘记密码？</div>
  </van-divider>
    </div>
  </div>
</template>
<script>
import cookieUtil from '../utils/CookieUtil'
export default {
  name: 'Login',
  data(){
    return{
      account:'',
      password:''
    }
  },
  methods:{
    login(){
      this.axios.post('/app/login', {
                  mobile:this.account,
                  password:this.password
                }).then((response) => {
                  if(response==500){
                    return
                  }
                  cookieUtil.setCookie(cookieUtil.token,response.token,response.expire);
                  this.$router.push("/");
                }).catch(function (error) {
                    console.log(error);
                });
    }
}
}
</script>

<style scoped>
  .login{
    padding: 15px 10px;
  }
  .login img{
        width: 50%;
    padding: 24%;
  }
  .van-cell-group{
    margin: 10px 0;
    border-bottom: 1px solid #e7e7e7;
  }
  .login-btn{
    width: 95%;
    height: 45px;
    line-height: 45px;
    margin: 15px auto 0;
    text-align: center;
    color: #FFFFFF;
    background: #00ef83;
    border-radius: 5px;
  }
  .center{
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 10px;
    bottom: 20px;
    border: 1px solid #d9d9d9;
    background-color: white;
    padding: 10px;
    box-shadow: 4px 10px 4px #e1e1e1;
  }
  .register-btn{
    margin-right: 2rem;
  }
  .password-btn{
    margin-left: 2rem;
  }
</style>
